import React, { useState, useEffect } from 'react';
import {
    delay,
    trackEngagement,
    API_URL,
    fetchWithRetry,
    addDebugDays,
} from './utils';

const Story = ({
    storyId,
    lastSeen,
    startStreaming,
    finishedStreamingCallback,
}) => {
    const [message, setMessage] = useState(null);
    const [displayedText, setDisplayedText] = useState('');
    const [isStreaming, setStreaming] = useState(true);
    const [shouldAbort, setShouldAbort] = useState(false);

    const fetchData = async (signal) => {
        const url = new URL(API_URL + 'story');

        url.searchParams.append('uuid', storyId);
        url.searchParams.append('timestamp', lastSeen);
        url.searchParams.append('personal', 'true');

        addDebugDays(url);

        try {
            const response = await fetchWithRetry(
                url.toString(),
                { signal },
                3,
            );
            const data = await response.json();
            if (data?.message) {
                // Check reference_check_passed
                if (!data.message.reference_check_passed) {
                    setShouldAbort(true);
                    return; // Abort further processing
                }
                setMessage(data.message);
            }
        } catch (error) {
            if (!signal.aborted) {
                console.error('Error fetching personalized stories:', error);
            }
        }
    };

    const isIosSafari = () => {
        const userAgent = window.navigator.userAgent;
        return (
            /iPhone|iPad|iPod/.test(userAgent) &&
            /Safari/.test(userAgent) &&
            !/Chrome/.test(userAgent)
        );
    };

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetchData(signal);

        const handlePageShow = (event) => {
            // There is a bug in iOS Safari and back/forward cache, causing in-flight requests to go missing. By listening
            // to the pageshow event, we can detect when the page is fetched from cache on Safari but has no data yet, so we
            // can refetch the data.
            if (event.persisted && isIosSafari() && !message) {
                console.log(
                    'Page was fetched from cache on iOS Safari with no data; refetching data',
                );
                fetchData(signal);
            }
        };

        window.addEventListener('pageshow', handlePageShow);

        return () => {
            abortController.abort();
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, [storyId]);

    useEffect(() => {
        if (startStreaming && message?.short_summary) {
            streamText(message.short_summary);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message?.short_summary, startStreaming]);

    const streamText = async (text, delayMs = 0) => {
        setStreaming(true);
        let currentText = '';
        for (const char of text) {
            await delay(delayMs);
            currentText += char;
            setDisplayedText(currentText);
        }
        setStreaming(false);
        finishedStreamingCallback(message?.short_summary);
    };

    const handleReadMore = () => {
        if (message) {
            trackEngagement('Click', {
                storyId: message.uuid,
                contentId: 'headline',
                id: 'headline',
                name: message.story_name,
            });
        }
    };

    // If reference_check_passed is false, abort rendering
    if (shouldAbort) {
        return null;
    }

    return (
        <li
            className={
                'story-list-item' +
                (startStreaming ? ' streaming' : ' not-streaming') +
                (displayedText ? ' has-text' : ' no-text')
            }
            id={message ? `story-${message.uuid}` : ''}
        >
            {message ? (
                <>
                    <p>
                        {displayedText.split('').map((char, index) => (
                            <span key={index} className="character">
                                {char}
                            </span>
                        ))}
                        {isStreaming && <span className="summary-loading" />}
                        {!isStreaming && (
                            <a
                                href={`https://www.vg.no/i/${message.source}`}
                                onClick={handleReadMore}
                                className="read-more"
                            >
                                Les mer
                            </a>
                        )}
                    </p>
                </>
            ) : (
                <span className="summary-loading" />
            )}
        </li>
    );
};

export default Story;
